import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
//Vue.use(ElementUI);
// 导入全局样式
import 'vant/lib/index.css'
import '@/assets/css/icon.css'
import 'amfe-flexible'//自适应字体



// 懒加载
import { Lazyload,Toast } from 'vant'

Vue.use(Toast);
// 懒加载图片
Vue.use(Lazyload, {
  lazyComponent: true,
  preLoad: 1,
  //error: require('@/assets/imgs/sese.jpg'),
  //loading: require('@/assets/imgs/ave.gif'),
  attempt: 2,
})
// import { Dialog } from 'vant'
// Vue.use(Dialog)
// Vue.use(Lazyload)

// 引入全局过滤器
import filters from '@/assets/formatnumbers'
Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]))

Vue.prototype.$filters = filters

Vue.use(Vant)

// 全局事件总线
Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false

const $Vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

export default $Vue
